import React from "react"
import { Layout } from "../components"

function TOU() {
  return (
    <Layout title="Terms of Use">
      <div className="text-justify px-10 md:px-20">
        <h1 className="mb-10 md:md-20">Terms of Use</h1>
        <p style={{ textAlign: "justify" }} className="mb-4">
          Welcome to Livecycle (together with its domains, subdomains, Content,
          Marks and services, the &ldquo;
          <span style={{ fontWeight: "bold" }}>Platform</span>&rdquo;). Please
          read the following Terms of Use carefully before using this Platform
          so that you are aware of your legal rights and obligations with
          respect to Livecycle Technologies Ltd. (&ldquo;
          <span style={{ fontWeight: "bold" }}>Livecycle</span>&rdquo;, &ldquo;
          <span style={{ fontWeight: "bold" }}>we</span>&rdquo;, &ldquo;
          <span style={{ fontWeight: "bold" }}>our</span>&rdquo;, &ldquo;
          <span style={{ fontWeight: "bold" }}>us</span>&rdquo; or &ldquo;
          <span style={{ fontWeight: "bold" }}>Licensor</span>&rdquo;). By
          selecting the box &ldquo;I agree&rdquo; or otherwise accessing or
          using the Platform as a software-as-a-service (SaaS) basis, you
          expressly acknowledge and agree that you are entering a legal
          agreement with us and have understood and agree to comply with, and be
          legally bound by, these Terms of Use, together with the Livecycle
          Privacy Policy (the &ldquo;
          <span style={{ fontWeight: "bold" }}>Terms</span>&rdquo;). You
          represent and warrant that you are at least 18 years old and, if you
          are entering into these Terms on behalf of your employer or other
          legal entity, that you have full authority to bind said employer or
          other legal entity to these Terms. You hereby waive any applicable
          rights to require an original (non-electronic) signature or delivery
          or retention of non-electronic records, to the extent not prohibited
          under applicable law. If you do not agree to be bound by these Terms
          please do not access or use the Platform.&nbsp;&nbsp;
        </p>
        <ol className="mb-4">
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Background.</strong> The Platform is intended to enable
              customers to collaborate during the development stage of the
              product life cycle. Our services include building and running of a
              live environment exposed to all stakeholders of the company while
              allowing communication of issues and feedback with the development
              team (the &ldquo;Service(s)&rdquo;). The services are made
              available to you via software-as-a-service (SaaS) basis and
              include: (i) the software that you access via the Platform; (ii)
              the products, services and features made available or provided to
              you by Livecycle in connection with the Service; and (iii) the
              content, text, documents, articles, brochures, descriptions,
              products, graphics, photos, sounds, videos, interactive features,
              and the trademarks, service marks and logos (&ldquo;Marks&rdquo;),
              contained in or made available through the Service.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Modification.</strong> We reserve the right, at our
              discretion, to change these Terms at any time. Such change will be
              effective 10 days following posting of the revised Terms via the
              Platform, and your continued use of the Platform thereafter means
              that you accept those changes.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Platform Access.</strong> For such time as these Terms are
              in effect, we hereby grant you permission to visit and use the
              Platform provided that you comply with these Terms and applicable
              law.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Subscription to the Service.</strong> Subject to your
              compliance with these Terms and payment of applicable fees, if and
              as applicable,Livecycle hereby grants you, and you accept, a
              non-exclusive, non-transferable, non-sublicensable, and fully
              revocable right to access and use the Service during the Term (as
              defined below) for your internal purposes only.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Restrictions on Use.</strong> You must not, and shall not
              allow any third party to: (i) give, sell, rent, lease, timeshare,
              sublicense, disclose, publish, assign, market, transfer or
              distribute any portion of the Service to any third party,
              including, but not limited to your affiliates, or use the Service
              in any service bureau arrangement; (ii) circumvent, disable or
              otherwise interfere with security-related features of the Service
              or features that prevent or restrict use or copying of any content
              or that enforce limitations on use of the Service; (iii) reverse
              engineer, decompile or disassemble the Service or any components
              thereof; (iv) modify, translate, patch, alter, change or create
              any derivative works of the Service, or any part thereof; (v) use
              any robot, spider, scraper, or other automated means to access the
              Service for any purpose; (vi) take any action that imposes or may
              impose (at Livecycle&rsquo;s sole discretion) an unreasonable or
              disproportionately large load on the Livecycle infrastructure;
              (vii) interfere or attempt to interfere with the integrity or
              proper working of the Service, or any related activities; (viii)
              remove, deface, obscure, or alter Livecycle&rsquo;s or any third
              party&rsquo;s copyright notices, trademarks, or other proprietary
              rights affixed to or provided as part of the Service, or use or
              display logos of the Service differing from Livecycle&rsquo;s own
              without Livecycle&rsquo;s prior written approval; (ix) use the
              Service to develop a competing service or product; (x) use the
              Service to send unsolicited or unauthorized commercial
              communications and/or (xi) use the Service in any unlawful manner
              or in breach of these Terms. You agree to remain liable to
              Livecycle for any act or omission of any third party acting on
              your behalf that would constitute a breach of these Terms if such
              acts or omissions were by you.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Account.</strong> In order to use the Service, you have to
              create an account (&ldquo;Account&rdquo;). You must not allow
              anyone other than yourself or your respective employees to access
              and use your Account. You acknowledge and agree: (i) to provide
              accurate and complete Account and login information (e.g., full
              name, email and job title); (ii) to keep all Account login details
              and passwords secure at all times; (iii) to remain solely
              responsible and liable for the activity that occurs in connection
              with your Account; and (iv) to promptly notify Livecycle in
              writing if you become aware of any unauthorized access or use of
              your Account or the Service.&nbsp; &nbsp; &nbsp; You may cancel
              your Account at any time by sending a cancellation request to us
              at support@livecycle.io, in which case we will use commercially
              reasonable efforts to respond within a reasonable time.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Trial Period.</strong> We may offer a trial period of the
              Service (&ldquo;Trial Period&rdquo;). The Trial Period is
              usage-based and limited to a certain amount of users. You
              acknowledge and agree that these Terms are applicable and binding
              upon you during the Trial Period.
            </p>
          </li>
          <li>
            <p>
              <strong>Intellectual Property Rights.</strong>
            </p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                <p style={{ textAlign: "justify" }}>
                  <u>Title.</u> Title and full, exclusive ownership rights of
                  the Service (and all parts thereof), and all reproductions,
                  corrections, modifications, enhancements and improvements, and
                  all related patent rights, copyrights, trade secrets,
                  trademarks, service marks, related goodwill, including data
                  related to your usage thereof, and Livecycle&rsquo;s
                  intellectual property, and any rights therein not explicitly
                  granted to you hereunder, are reserved to and shall remain
                  solely and exclusively proprietary to Livecycle (or its third
                  party licensors). &ldquo;Livecycle&rdquo;, the Livecycle Logo,
                  and other marks are Marks of Livecycle or its affiliates. If
                  you download or print a copy of the Service you must retain
                  all copyright and other proprietary notices contained therein.
                </p>
              </li>
              <li>
                <p style={{ textAlign: "justify" }}>
                  <u>Use of Content.</u> Content (as defined herein) on the
                  Platform is provided to you for your information and personal
                  use only and may not be used, modified, copied, distributed,
                  transmitted, broadcast, displayed, sold, licensed, decompiled,
                  or otherwise exploited for any other purposes whatsoever
                  without our prior written consent. If you download or print a
                  copy of the Content you must retain all copyright and other
                  proprietary notices contained therein. &ldquo;Content&rdquo;
                  shall mean any content on the Platform, including without
                  limitation: (i) the text, documents, articles, brochures,
                  descriptions, products, software, graphics, photos, sounds,
                  videos, interactive features and services; and (ii) Marks
                  contained in or made available through the Service.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Third Party Sources and Content.&nbsp;</strong>
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    The Platform may enable you to view, access, link to, and
                    use content from Third Party Sources that are not owned or
                    controlled by us (
                    <strong>&ldquo;Third Party Content&ldquo;</strong>). The
                    Platform may also enable you to communicate and interact
                    with Third Party Sources.{" "}
                    <strong>&ldquo;Third Party Source(s)&rdquo;</strong> means
                    third party websites and services.&nbsp;
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    We are not affiliated with and have no control over any
                    Third Party Sources. We do not assume any responsibility for
                    the content, terms of use, privacy policies, actions or
                    practices of, any Third Party Sources. Please read the terms
                    of use and privacy policy of any Third Party Source that you
                    interact with before you engage in any such activity.&nbsp;
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    We are not responsible for, and we expressly disclaim all
                    warranties regarding, the accuracy, appropriateness,
                    usefulness, safety, or Intellectual Property Rights (as
                    defined below) of, or relating to, any Third Party
                    Content.&nbsp;
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    We do not endorse any advertising, promotions, campaigns,
                    products, services or other materials that is included in
                    any Third Party Content or that is communicated to you from
                    a Third Party Source.
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    You always have the choice of deciding whether or not to
                    interact with a Third Party Source or to view and use Third
                    Party Content. Your interaction with a Third Party Source
                    and your use of, and reliance upon, any Third Party Content
                    is at your sole discretion and risk.&nbsp;
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    You are solely responsible and liable for your interaction
                    with a Third Party Source. You agree to waive, and hereby do
                    waive, any legal or equitable rights or remedies you may
                    have against Livecycle, and release Livecycle from any and
                    all liability, arising from your use of and interaction on
                    any Third Party Content and from your interaction with any
                    Third Party Source. If you have any query or complaint
                    regarding a Third Party Source or any Third Party Content,
                    you agree to contact the Third Party Source directly.
                  </p>
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>User Content</strong>
            </p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  Responsibility
                </span>
                . The Platform may permit the accessing, transmitting, temporary
                hosting and sharing of content by you, including your respective
                Third Party Content (&ldquo;
                <span style={{ fontWeight: "bold" }}>User Content</span>
                &rdquo;).You shall be solely responsible for your User Content
                and the consequencesof accessing, transmitting, temporary
                hosting and sharing such User Content via the Platform.&nbsp;
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>Ownership</span>.
                You represent and warrant that you own or have the necessary
                rights and permissions to use and authorize Livecycle to use all
                Intellectual Property Rights in and to your User Content, and to
                enable inclusion and use thereof as contemplated by the Platform
                and these Terms. &ldquo;
                <span style={{ fontWeight: "bold" }}>
                  Intellectual Property Rights
                </span>
                &rdquo; means any and all rights, titles and interests, whether
                foreign or domestic, in and to any and all trade secrets,
                patents, copyrights, service marks, trademarks, know-how, or
                similar intellectual property rights, as well as any and all
                moral rights, rights of privacy, publicity and similar rights of
                any type under the laws or regulations of any governmental,
                regulatory, or judicial authority, foreign or domestic.
                Youretain all of your ownership rights in and to your User
                Content.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Feedback.</strong> It is anticipated that you may provide
              suggestions, comments or other feedback to Livecycle regarding the
              Service and/or the Platform, including but not limited to, new
              features, functionality, techniques or business methods
              (&ldquo;Feedback&rdquo;). Feedback will not create any
              confidentiality obligation for Livecycle and shall be deemed the
              sole property of Livecycle. Without derogating from the above,
              Livecycle will be free to adopt such items for any of its products
              or services, use it in any other manner, disclose, reproduce,
              license or otherwise distribute and exploit the Feedback provided
              to it as it sees fit, entirely without obligation or restriction
              of any kind on account of intellectual property rights or
              otherwise. You hereby waive any right to the Feedback, including
              but limited to, any right for royalties or any other
              consideration. Furthermore, you hereby undertake not to disclose
              to Livecycle any confidential information or proprietary
              information of any third party.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Information Description.</strong> We attempt to be as
              accurate as possible. However, we cannot and do not warrant that
              the Content available on the Platform is accurate, complete,
              reliable, current, or error-free. We reserve the right to make
              changes in or to the Content, or any part thereof, in our sole
              judgment, without the requirement of giving any notice prior to or
              after making such changes to the Content. Your use of the Content,
              or any part thereof, is made solely at your own risk and
              responsibility.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Disclosure.</strong> We reserve the right to access, read,
              preserve, and disclose any information that we obtain in
              connection with the Platform, and your use thereof, as we
              reasonably believe is necessary to: (i) satisfy any applicable
              law, regulation, legal process, subpoena or governmental request;
              (ii) enforce these Terms, including to investigate potential
              violations of them; (iii) detect, prevent or otherwise address
              fraud, security or technical issues; (iv) respond to user support
              requests; or (v) protect the rights, property or safety of
              Livecycle, its users or the public.
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              <strong>Privacy Policy.</strong> You acknowledge and agree that
              your use of the Service, including, without limitation, any
              information transmitted to or temporarily stored by Livecycle, is
              governed by the Livecycle Privacy Policy at [Please
              complete](&ldquo;Privacy Policy&rdquo;).You agree that we may use
              information that you provide or make available to us in accordance
              with the Privacy Policy.
            </p>
          </li>
          <li>
            <p>
              <strong>Warranty Disclaimers.</strong>
            </p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                This section applies whether or not the services provided under
                the Platform are for payment. Applicable law may not allow the
                exclusion of certain warranties, so to that extent certain
                exclusions set forth herein may not apply.&nbsp;
              </li>
              <li>
                THE PLATFORM IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
                AVAILABLE&rdquo; BASIS, AND WITHOUT WARRANTIES OF ANY KIND
                EITHER EXPRESS OR IMPLIED. LIVECYCLE HEREBY DISCLAIMS ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
                IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
                PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING BY
                STATUTE OR FROM A COURSE OF DEALING OR USAGE OF TRADE. LIVECYCLE
                DOES NOT GUARANTEE THAT THE PLATFORM WILL BE FREE OF BUGS,
                SECURITY BREACHES, OR VIRUS ATTACKS. THE PLATFORM MAY
                OCCASIONALLY BE UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING,
                OR OTHER REASONS. YOU AGREE THAT LIVECYCLE WILL NOT BE HELD
                RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT
                MAY RESULT FROM TECHNICAL PROBLEMS OF THE INTERNET, SLOW
                CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER
                SERVERS. WE DO NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT,
                PRODUCT, OR SERVICE THAT IS FEATURED OR ADVERTISED ON THE
                PLATFORM BY A THIRD PARTY.&nbsp;
              </li>
              <li>
                LIVECYCLE DOES NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT,
                AND DOES NOT MAKE ANY REPRESENTATION OR WARRANTY WITH RESPECT
                TO, AND DISCLAIMS ALL LIABILITY FOR, ANY SUCH CONTENT.&nbsp;
              </li>
              <li>
                YOU SPECIFICALLY ACKNOWLEDGE THAT LIVECYCLE SHALL NOT BE
                RESPONSIBLE FOR THE USER CONTENT.&nbsp;
              </li>
              <li>
                YOUR RELIANCE ON, OR USE OF, ANY OF YOUR USER CONTENT, OR
                INTERACTION WITH THIRD PARTY SOURCES, IS AT YOUR SOLE RISK. IF
                YOU HAVE A DISPUTE WITH ANY THIRD PARTY SOURCES IN CONNECTION
                WITH THE PLATFORM OR ANY USER CONTENT, YOU AGREE THAT LIVECYCLE
                IS NOT LIABLE FOR ANY CLAIMS OR DAMAGES ARISING OUT OF OR
                CONNECTED WITH SUCH A DISPUTE. LIVECYCLE RESERVES THE RIGHT, BUT
                HAS NO OBLIGATION, TO MONITOR ANY SUCH DISPUTE.
              </li>
              <li>
                EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, LIVECYCLE DOES
                NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY
                KIND, EXPRESS OR IMPLIED, AS TO THE SECURITY OF ANY INFORMATION
                YOU MAY PROVIDE OR ACTIVITIES YOU ENGAGE IN DURING THE COURSE OF
                YOUR USE OF THE PLATFORM.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Limitation of Liability</strong>
            </p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                TO THE FULLEST EXTENT PERMISSIBLE BY LAW, LIVECYCLE SHALL NOT BE
                LIABLE FOR ANY INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR
                INCIDENTAL DAMAGES OF ANY KIND, OR FOR ANY LOSS OF DATA,
                REVENUE, PROFITS OR REPUTATION, ARISING UNDER THESE TERMS OR OUT
                OF YOUR USE OF, OR INABILITY TO USE, THE PLATFORM, EVEN IF
                LIVECYCLE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                Some jurisdictions do not allow the limitation or exclusion of
                liability for incidental or consequential damages, so the above
                limitations may not apply to you.
              </li>
              <li>
                IN NO EVENT SHALL THE AGGREGATE LIABILITY OF LIVECYCLE FOR ANY
                DAMAGES ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR
                INABILITY TO USE, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE PLATFORM
                EXCEED THE TOTAL AMOUNT OF SUBSCRIPTION FEES PAID BY YOU FOR THE
                SERVICE DURING THE THREE (3) MONTHS PERIOD PRIOR TO BRINGING THE
                CLAIM.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Indemnity.</strong> You agree to defend, indemnify and
              hold harmless Livecycle and our affiliates, and our respective
              officers, directors, employees and agents, from and against any
              and all claims, damages, obligations, losses, liabilities, costs
              and expenses (including but not limited to attorney&rsquo;s fees)
              arising from: (i) your use of, or inability to use, the Platform;
              (ii) your interaction with any Third Party Sources; or (iv) your
              violation of these Terms.
            </p>
          </li>
          <li>
            <p>
              <strong>Term and Termination</strong>
            </p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                These Terms shall become effective on the earlier of: (i) the
                date that you commence access to or use of the Service; or (ii)
                the date that we receive payment of any applicable Service
                subscription fee, and shall continue until expiration of the
                subscription period (the &ldquo;
                <span style={{ fontWeight: "bold" }}>Initial</span>
                <span style={{ fontWeight: "bold" }}>Term</span>&rdquo;).
                Thereafter, subject to your payment of any applicable Service
                subscription fees, these Terms shall automatically renew for
                successive subscription periods (each a &ldquo;
                <span style={{ fontWeight: "bold" }}>Renewal Term</span>&rdquo;,
                and together with the Initial Term, the &ldquo;
                <span style={{ fontWeight: "bold" }}>Term</span>&rdquo;), or
                until these Terms are terminated in accordance with the terms
                herein. If you continue to use the Service past any subscription
                period renewal date, you shall be deemed to have renewed these
                Terms for the corresponding Renewal Term.
              </li>
              <li>
                Without derogating from your obligation to pay the subscription
                fees, you may terminate these Terms at any time by cancelling
                your Account. We may stop to provide the Service to you at any
                time, including without limitation in the event that you breach
                these Terms.&nbsp;
              </li>
              <li>
                This Section 18 and Sections 8 (
                <span style={{ fontStyle: "italic" }}>
                  Intellectual Property Rights
                </span>
                ), 14 (
                <span style={{ fontStyle: "italic" }}>Privacy Policy</span>
                ), 15 (
                <span style={{ fontStyle: "italic" }}>
                  Warranty Disclaimers
                </span>
                ), 16 (
                <span style={{ fontStyle: "italic" }}>
                  Limitation of Liability
                </span>
                ), 17 (<span style={{ fontStyle: "italic" }}>Indemnity</span>),
                and 19 (
                <span style={{ fontStyle: "italic" }}>
                  Independent Contractors
                </span>
                ) to 21 (<span style={{ fontStyle: "italic" }}>General</span>)
                shall survive termination of these Terms.
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Independent Contractors.</strong> You and Livecycle are
              independent contractors. Nothing in these Terms creates a
              partnership, joint venture, agency, or employment relationship
              between you and Livecycle. You must not under any circumstances
              make or undertake any warranties, representations, commitments or
              obligations on behalf of Livecycle.
            </p>
          </li>
          <li>
            <p>
              <strong>Assignment.</strong> These Terms, and any rights and
              licenses granted hereunder, may not be transferred or assigned by
              you but may be assigned by Livecycle without restriction or
              notification to you.
            </p>
          </li>
          <li>
            <p>
              <strong>General.</strong> Livecycle reserves the right to
              discontinue or modify any aspect of the Platform at any time.
              These Terms and the relationship between you and Livecycle shall
              be governed by and construed in accordance with the laws of the
              State of Israel, without regard to its principles of conflict of
              laws. You agree to submit to the personal and exclusive
              jurisdiction of the courts located in Tel Aviv-Jaffa and waive any
              jurisdictional, venue, or inconvenient forum objections to such
              courts, provided that Livecycle may seek injunctive relief in any
              court of competent jurisdiction. These Terms shall constitute the
              entire agreement between you and Livecycle concerning the
              Platform. If any provision of these Terms is deemed invalid by a
              court of competent jurisdiction, the invalidity of such provision
              shall not affect the validity of the remaining provisions of these
              Terms, which shall remain in full force and effect. No waiver of
              any term of these Terms shall be deemed a further or continuing
              waiver of such term or any other term, and a party&rsquo;s failure
              to assert any right or provision under these Terms shall not
              constitute a waiver of such right or provision. YOU AGREE THAT ANY
              CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO THE
              PLATFORM MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
              ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
              BARRED.
            </p>
          </li>
        </ol>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
            Last updated: April 2021
          </span>
        </p>
      </div>
    </Layout>
  )
}

export default TOU
